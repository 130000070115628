<template>
	<div class="main-content">
		<ktv-breadcrumb
			title="Customer List"
			page="Customer"
			folder="Customer"
		/>
		<ktv-table
			id="customerlist"
			:columns="columns"
			:is-loading="isLoading"
			:rows="rows"
			:total-rows="totalRecords"
			:mode="modeTable"
			:filter="false"
			search-placeholder="Search by Customer Name"
			@on-open-filter="openFilter()"
			@on-column-filter="onColumnFilter"
			@on-page-change="onPageChange"
			@on-per-page-change="onPerPageChange"
			@on-search="onSearchFilter"
			@on-sort-change="onSortChange"
		>
			<template #actionleft>
				<ktv-button text="Export" icon="download" color="green-light" @click="exportCustomer()" />
			</template>
			<template #rows="{ props }">
				<span v-if="props.column.field == 'button' && props.row.CustomerId">
					<b-dropdown size="xs" variant="link" toggle-class="text-decoration-none" no-caret>
						<template #button-content>
							<span>
								<img :src="icondropdown">
							</span>
						</template>
						<b-dropdown-item class="dropdownitem-action" @click="openForm('View', props.row)">
							<img :src="iconview" class="imgicon"> View
						</b-dropdown-item>
					</b-dropdown>
				</span>
				<span v-else-if="props.column.field == 'ProvinceName' && props.row.CustomerId">
					<template v-if="props.row.Province === '' || props.row.Province === null">
						-
					</template>
					<template v-else>
						<img :src="iconlocation" class="imgicongroup"> {{ props.row.Province }}, {{ props.row.District }}
					</template>
				</span>
				<span v-else-if="props.column.field == 'RegisteredDate' && props.row.CustomerId">
					{{ formatDate(props.row.RegisteredDate) }}
				</span>
			</template>
		</ktv-table>

		<ktv-modal
			v-model="modalsFilter"
			title="Filter"
			size="md"
			@close="modalsFilter = false"
		>
			<template #content>
				<div class="form-group row">
					<label class="col-sm-4 col-form-label">Farmer Type</label>
					<div class="col-sm-7">
						<b-form-radio-group
							v-model="filter.farmerType"
							:options="optionFarmer"
						/>
					</div>
				</div>
				<div class="form-group row mt-3">
					<label class="col-sm-4 col-form-label">Province</label>
					<div class="col-sm-7">
						<b-form-select
							v-model="filter.province"
							:options="optionProvince"
							@change="changeCmb('province', $event)"
						/>
					</div>
				</div>
				<div class="form-group row mt-3">
					<label class="col-sm-4 col-form-label">District</label>
					<div class="col-sm-7">
						<b-form-select
							v-model="filter.district"
							:options="optionDistrict"
							:disabled="optionDistrict.length > 0 ? false: true"
							@change="changeCmb('district', $event)"
						/>
					</div>
				</div>
				<div class="form-group row mt-3">
					<label class="col-sm-4 col-form-label">Sub District</label>
					<div class="col-sm-7">
						<b-form-select
							v-model="filter.subDistrict"
							:options="optionSubDistrict"
							:disabled="optionSubDistrict.length > 0 ? false: true"
						/>
					</div>
				</div>
			</template>
			<template #footer>
				<div align="right">
					<ktv-button
						text="Apply Filter"
						color="primary"
						icon="i-Filter-2"
						style="margin-right: 5px"
						@click="applyFilter()"
					/>

					<ktv-button
						text="Close"
						color="light"
						icon="i-Close"
						style="margin-right: 5px"
						@click="modalsFilter = false"
					/>
				</div>
			</template>
		</ktv-modal>
	</div>
</template>
<script>
	import { KtvTable, KtvModal, KtvButton } from "@/components"
	import { mapActions, mapGetters, mapMutations } from 'vuex'
	import * as XLSX from 'xlsx'

	export default {
		metaInfo: {
			title: "Customer List",
		},
		components: { KtvTable, KtvModal, KtvButton },
		data() {
			return {
				/* eslint-disable global-require */
				iconview: require("@/assets/images/icon/iconview.png"),
				icondropdown: require("@/assets/images/icon/icondropdown.png"),
				iconlocation: require("@/assets/images/icon/iconlocation.png"),
				isLoading: false,
				modeTable: "remote",
				columns: [
					{
						label: "Action",
						field: "button",
						html: true,
						tdClass: "text-center",
						thClass: "text-center",
					},
					{
						label: "Customer Name",
						field: "PersonName",
						thClass: "text-left",
					},
					{
						label: "Phone Number",
						field: "PhoneNumber",
						thClass: "text-left",
					},
					{
						label: "Location",
						field: "ProvinceName",
						thClass: "text-left",
					},
					// {
					// 	label: "Province",
					// 	field: "Province",
					// 	thClass: "text-left",
					// },
					// {
					// 	label: "District",
					// 	field: "District",
					// 	thClass: "text-left",
					// },
					{
						label: "Registered by SME",
						field: "RegisteredBySME",
						thClass: "text-left",
					},
					{
						label: "Registered Date",
						field: "RegisteredDate",
						thClass: "text-left",
					}
				],
				rows: [],
				totalRecords: 0,
				serverParams: {
					columnFilters: {
					},
					sort: [{
						field: "",
						type: ""
					}],
					currentPage: 1,
					rowPerpage: 10,
				},
				modalsFilter: false,
				filter: {
					farmerType: '',
					province: '',
					district: '',
					subDistrict: ''
				},
				optionFarmer: [
					{ text: 'Koltiva Farmer', value: '13' },
					{ text: 'Non Koltiva Farmer', value: '74' },
				],
				serverParamsExport: {
					columnFilters: {
					},
					sort: [{
						field: "",
						type: ""
					}],
					currentPage: 1,
					rowPerpage: 99999,
				},
				listCustomer: [],
			}
		},
		computed: {
			...mapGetters({
				optionProvince: "MASTER/getProvince",
				optionDistrict: "MASTER/getDistrict",
				optionSubDistrict: "MASTER/getSubDistrict",
			})
		},
		mounted() {
			this.loadItems()
			this.loadOption()
		},
		methods: {
			...mapActions("MASTER", ["actGetProvince", "actGetDistrict", "actGetSubDistrict"]),
			...mapActions("CUSTOMER", ["pushData"]),
			...mapMutations("MASTER", ["clearRegion"]),
			loadOption() {
				this.actGetProvince();
			},
			applyFilter() {
				this.onColumnFilter(this.filter);
			},
			changeCmb(item, id) {
				if (item === 'province') {
					this.clearRegion()
					this.actGetDistrict(id)
				} else if (item === 'district') {
					this.clearRegion('subdistrict')
					this.actGetSubDistrict(id);
				}
			},
			openFilter() {
				this.clearRegion();
				this.modalsFilter = true;
			},
			openForm(opsi, items = []) {
				let data = {
					opsiDisplay: opsi,
					customerID: items.CustomerId,
				}
				this.pushData(data);
				this.$router.push('/customer/mainform')
			},
			updateParams(newProps) {
				this.serverParams = Object.assign({}, this.serverParams, newProps)
			},
			onPageChange(params) {
				this.updateParams({ currentPage: params.currentPage })
				this.loadItems()
			},
			onPerPageChange(params) {
				this.updateParams({ rowPerpage: params.currentPerPage })
				this.loadItems()
			},
			onSortChange(params) {
				this.updateParams({
					sort: [
						{
							type: params[0].type,
							field: params[0].field,
						},
					],
					currentPage: 1
				})
				this.loadItems()
			},
			onColumnFilter(params) {
				this.updateParams({
					columnFilters: params,
				})
				this.loadItems()
			},
			onSearchFilter(event) {
				this.updateParams({
					columnFilters: { PersonName : event },
				})
				this.loadItems()
			},
			loadItems() {
				this.isLoading = true
				this.$http
					.Post(this.serverParams, this.$urlApi.customer.list)
					.then((response) => {
						this.isLoading = false
						if (response.success) {
							this.totalRecords = response.results.total
							this.rows = response.results.data
							if(Object.keys(this.rows).length < 10 && Object.keys(this.rows).length !== 0) {
								this.rows = response.results.data
								for(let i=0; Object.keys(this.rows).length<10; i++) {
									this.rows.push({});
								}
							}
						} else {
							this.$swal("Error!", response.error_massage, "error")
						}
					})
					.catch((error) => {
						this.isLoading = false
						this.$swal("Error!", error.response.error_massage, "error")
					})
			},
			exportCustomer() {
				this.$swal.fire({
					title: 'Loading...',
					allowOutsideClick: false,
					onBeforeOpen: () => {
						this.$swal.showLoading()
						this.listCustomer = []
						this.$http
							.Post(this.serverParamsExport, this.$urlApi.customer.list)
							.then((r) => {
								if (r.success) {
									r.results.data.forEach((e, key) => {
										this.listCustomer.push({
											No: key+1,
											EntityID: e.EntityId,
											Customer_Name: e.PersonName,
											Customer_Phone_Number: e.PhoneNumber,
											Location: e.District,
											Registered_by_SME: e.RegisteredBySME,
											Registered_Date: e.RegisteredDate,
										})
									})
								} else {
									this.$swal("Error!", r.error_massage, "error")
								}
								this.exportToExcel()
							})
							.catch((e) => {
								this.$swal("Error!", e.response.data.error_message, "error")
							}).finally(() => this.$swal.close())
					}
				})
			},
			exportToExcel() {

				var dataCustomerWS = XLSX.utils.json_to_sheet(this.listCustomer)

				var wb = XLSX.utils.book_new()

				XLSX.utils.book_append_sheet(wb, dataCustomerWS, 'data_customer') // sheetAName is name of Worksheet

				const today = new Date();
				const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
				const time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
				const dateTime = date +' '+ time;
				this.timestamp = dateTime;

				XLSX.writeFile(wb, 'FarmRetail_Customer_'+this.timestamp+'.xlsx') // name of the file is 'book.xlsx'
			},
		},
	}
</script>
